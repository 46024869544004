<template>
  <v-dialog v-model="visible" max-width="500px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <div class="textBox">
        <v-text-field :label="label+'(한국어)'" placeholder="한국어" type="text" v-model="titleData.kr" />
        <v-text-field :label="label+'(영어)'" placeholder="영어" type="text" v-model="titleData.en" />
        <v-text-field :label="label+'(중국어)'" placeholder="중국어" type="text" v-model="titleData.ch" />
        <v-text-field :label="label+'(베트남어)'" placeholder="베트남어" type="text" v-model="titleData.bt" />
      </div>

      <CommonBtn :isShowSaveBtn="true" saveBtnLabel="OK" cancelBtnLabel="CLOSE" @save="save" @cancel="close" />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Header from "@/components/commonV2/Dialog/comp/Header.vue";
import CommonBtn from "@/components/commonV2/Dialog/medicalInfo/comp/CommonBtn.vue";
export default {
  components: {
    Header, CommonBtn
  },
  props: {
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
  },
  data() {
    return {
      titleData: {},
      visible: false,
    };
  },
  methods: {
    Open(titleData) {
      this.visible = true;
      this.titleData = titleData;
    },
    close() {
      this.visible = false;
    },
    // ok
    save() {
      this.visible = false;
      this.$emit('complete', this.titleData);
    },
    // ...mapActions(['saveDataQuery', 'signOut']),
  },
  computed: {
    ...mapGetters(["meInfo"]),
  },
};
</script>

<style lang="scss">
.textBox {
  margin-top: 50px;
}
</style>