<template>
  <v-col :cols="formRight">
    <v-row>
      <v-col cols="4">
        <v-text-field
          :label="$t('common.component.address.zip')"
          :placeholder="$t('common.component.address.zip')"
          ref="refZip"
          v-model="data.zipcode"
        />
      </v-col>
      <v-col cols="2">
        <v-btn color="primary" depressed small @click="onSearchKr">
          {{ $t('common.button.search') }}</v-btn>
      </v-col>
    </v-row>

    <TitleField 
      :label="$t('common.component.address.addr1')" 
      :placeholder="$t('common.component.address.addr1')"
      :titleData="data.address1" 
      @complete="data.address1 = $event"/>

    <TitleField 
      :label="$t('common.component.address.addr2')" 
      :placeholder="$t('common.component.address.addr2')"
      :titleData="data.address2" 
      @complete="data.address2 = $event"/>

    <!-- <v-text-field 
      :label="$t('common.component.address.addr1')"
      :placeholder="$t('common.component.address.addr1')"
      ref="refAddress1" 
      v-model="data.address1" 
    /> -->
    <!-- <v-text-field 
      :label="$t('common.component.address.addr2')"
      :placeholder="$t('common.component.address.addr2')"
      ref="refAddress2" 
      v-model="data.address2" 
    /> -->

    <!-- 우편번호 -->
    <AddressSearchDaum
      ref="refAddressSearch"
      @searchCompleteUse="searchCompleteUseHandler"
    />
  </v-col>
</template>
<script>
import { mapActions } from "vuex";
import TitleField from "@/components/commonV2/ui/lang/TitleField.vue";
import AddressSearchDaum from "@/components/commonV2/ui/AddressSearchDaum.vue";

export default {
  components: { TitleField, AddressSearchDaum },
  props: {
    data: {
      type: [Object, Array],
    },
    formLeft: {
      type: Number,
      default: 10,
    },
    formRight: {
      type: Number,
      default: 24,
    },
  },
  data() {
    return {};
  },
  methods: {
    async onSearchKr() {
      this.$refs.refAddressSearch.showModal();
    },
    searchCompleteUseHandler(e) {
      this.data.zipcode = e.zip;
      this.data.address1 = e.addr;
      this.data.address2 = "";
      setTimeout(() => {
        this.$refs.refAddress2.focus();
      }, 300);
      
      console.log("this.userData", this.data);
    },
    ...mapActions(["getApiDataQuery"]),
  },
};
</script>
<style lang="">
</style>