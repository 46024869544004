<template>
  <v-row>
    <v-col>
      <v-card-text class="card-title ma-0">Clinic</v-card-text>
    </v-col>
    <v-col cols="12" v-if="isHospitalOne === 'N'">
      <SearchTopUIComp
        placeholder="Name,Mobile Last digits 4"
        :propsSearchWorld="reqData.searchVal"
        @changeText="changeText"
        @changeSearchWordComplete="changeSearchWordComplete"
      />
    </v-col>
    <v-col cols="12" v-if="isHospitalOne === 'N'">
      <TopTable
        btnLabel="NEW"
        :btnIsShow="true"
        :IsSwitchShow="true"
        :xlsxBtnIsShow="false"
        :switchLabel="switchLabel"
        :data="clinicData"
        @clickXlsxBtn="xlsxSave"
        @clickEditorBtn="clickNewBtn"
        @clickReload="clickReload"
        @changeSwitch="changeSwitch"
      />
    </v-col>
    <v-col cols="12" v-if="isHospitalOne === 'N'">
      <DataTable
        ref="DataTable"
        :loading="loading"
        :loadingText="loadingText"
        :tableKey="'rowSeq'"
        :perPage="10000"
        :footerHide="true"
        :singleSelect="true"
        :showSelect="false"
        :columns="clinicInfoColumns"
        :inputData="clinicData"
        @clickRow="clickRow"
      />
    </v-col>

    <!-- add / edit -->
    <DialogClinicAdd ref="refClinicAdd" @complete="addComplete" />
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SearchTopUIComp from "@/components/commonV2/ui/SearchTopUIComp.vue";
import SelectBoxClinic from "@/components/commonV2/ui/SelectBoxClinic.vue";
import TopTable from "@/components/commonV2/ui/TopTable.vue";
import DataTable from "@/components/commonV2/table/DataTable.vue";
import DialogClinicAdd from "@/components/commonV2/Dialog/DialogClinicAdd.vue";
const formatService = require("@/utils/format.js");
const modelService = require("@/utils/dataModel.js");

export default {
  components: {
    SearchTopUIComp,
    TopTable,
    DataTable,
    SelectBoxClinic,
    DialogClinicAdd,
  },
  data() {
    return {
      loading: false,
      loadingText: "Loading list...",
      viewPhotoMode: "N",
      clinicData: [],
      clinicInfoColumns: formatService.clinicInfoTableColumnsV1(),
      isHospitalOne: "N",
      switchLabel: "Active",
      propsDate: [this.$moment().subtract(30, "year"), this.$moment()],
      reqData: modelService.reqDataModel(),
      isDev: process.env.NODE_ENV === "development",
    };
  },
  mounted() {
    this.reqData.endpoint = "hospital";
    if (this.meInfo.userType !== "super") {
      this.isHospitalOne = "Y";
      this.reqData.hospital_id = this.meInfo.hospital_id;
      this.reqData.endpoint = "hospitalOne";
    }
    this.getData();
  },
  methods: {
    /**** 검색어 ***/
    changeSearchWordComplete(e) {
      this.reqData.searchVal = e;
      this.getData();
    },
    changeText(e) {
      this.reqData.searchVal = e;
    },
    /**** 중간 ***/
    changeSwitch(e) {
      this.reqData.activated = e.length > 0 ? true : false;
      this.getData();
    },
    xlsxSave() {
      this.$helper.xlsxDownload(this.xlsxData, "sheet1", "ClinicList");
    },
    clickNewBtn() {
      let clinicOneData = modelService.hospitalModel();
      clinicOneData.hospital_id = this.reqData.hospital_id;
      this.$refs.refClinicAdd.Open(clinicOneData);
    },
    clickReload() {
      this.reqData.searchVal = "";
      this.selectedBtn = "all"; // 초기화할때 버튼과 date 값을 초기화 해야함
      this.propsDate = [this.$moment().subtract(30, "year"), this.$moment()];
      this.getData();
    },
    clickRow(e) {
      this.$refs.refClinicAdd.Open(e);
    },
    /**** 데이터읽기 ***/
    async getData() {
      this.list = [];
      const $h = this.$helper;
      this.reqData.fromDay = $h.changeDateToString(this.propsDate[0], false);
      this.reqData.toDay = $h.changeDateToString(this.propsDate[1], false);
      await this.getDataQuery(this.reqData).then((resP) => {
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: index + 1,
            regDateLabel: $h.changeDateToStringForDp(item.regDate, true),
            updDateLabel: $h.changeDateToStringForDp(item.updDate),
            useYNLabel: $h.memberTypeLbl(item.activated),
            signupPathLabel: $h.signupPathLabel(item.signupPath),
            isPhoto: $h.isPhotoChecker(item.hospitalPhotoImg),
            dpDateLbl: $h.changeDateToForUpdateDp(item.regDate, item.updDate),
          }));
        }
        // console.log(res);
        this.clinicData = res;
        if (this.isHospitalOne === "Y") {
          setTimeout(() => {
            this.$refs.refClinicAdd.Open(this.clinicData);
          }, 500);
        }
      });
    },
    /**** 저장/수정/삭제 후 처리 ***/
    addComplete(e) {
      this.getData();
    },
    ...mapActions(["getDataQuery"]),
  },
  computed: {
    ...mapGetters(["meInfo"]),
  },
};
</script>
<style lang="">
</style>