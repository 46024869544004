<template>
  <div class="subMain">
    <div class="headTitle">수수료 정보</div>
    <div class="_vGap"></div>
    <div class="edit_vGap" v-if="clinicData.user_id === 0"></div>
    <v-card-text>
      <ClinicCharge 
        prosTitle="병원 기본 수수료율" 
        :clinicData="clinicData" 
        :prosSurgery="clinicData.hospitalCharge.basicSurgery"
        :prosProcedure="clinicData.hospitalCharge.basicProcedure"
        @changeComplete="
          clinicData.hospitalCharge.basicSurgery = $event.surgery;
          clinicData.hospitalCharge.basicProcedure = $event.procedure"/>

      <ClinicCharge 
        prosTitle="중국환자 수수료율" 
        :clinicData="clinicData" 
        :prosSurgery="clinicData.hospitalCharge.cnSurgery"
        :prosProcedure="clinicData.hospitalCharge.cnProcedure"
        @changeComplete="
          clinicData.hospitalCharge.cnSurgery = $event.surgery;
          clinicData.hospitalCharge.cnProcedure = $event.procedure"/>

      <ClinicCharge 
        prosTitle="베트남환자 수수료율" 
        :clinicData="clinicData" 
        :prosSurgery="clinicData.hospitalCharge.btSurgery"
        :prosProcedure="clinicData.hospitalCharge.btProcedure"
        @changeComplete="
          clinicData.hospitalCharge.btSurgery = $event.surgery;
          clinicData.hospitalCharge.btProcedure = $event.procedure"/>
    </v-card-text>
  </div>
</template>
<script>
import ClinicCharge from "./comp/ClinicCharge.vue";

export default {
  components: {
    ClinicCharge,
  },
  props: {
    clinicData: {
      type: [Object, Array],
    },
  },
  watch: {
    clinicData(val) {
      if (val === null) return;
    },
  },
};
</script>
<style lang="scss">
.prot {
  padding-top: 20px;
}
.centered-input input {
  text-align: center;
}
</style>