<template>
  <div :class="isHorizontal ? 'checkBoxLayout' : ''">
    <!-- <p>{{ selected }}</p> -->
    <v-checkbox
      v-model="selected"
      v-for="(item, index) in items"
      :key="index"
      :color="item.color"
      :label="item.label"
      :value="item.value"
      :disabled="item.disabled"
      hide-details
      @change="changeType"
    />
  </div>
</template>
<script>
export default {
  props: {
    // 가로정렬 옵션
    isHorizontal: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
    },
    items: {
      type: [Object, Array],
    },
  },
  watch: {
    value(val) {
      this.selected = val.length === 0 ? [] : val;
    },
  },
  data() {
    return {
      selected: this.value && this.value.length === 0 ? [] : this.value,
    };
  },
  methods: {
    changeType(e) {
      this.$emit("changeType", this.selected);
    },
  },
};
</script>
<style lang="scss">
.checkBoxLayout {
  display: inline-flex;
}
.v-input__control {
  padding-right: 15px;
}
.v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
</style>