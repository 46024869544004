<template>
  <SelectBox
    label="병원종류"
    :selectStyleWidth="selectStyleWidth"
    :value="selectedValue"
    :items="itemsHospitalType"
    :multiple="false"
    :chips="false"
    @changeSelect="changeSelect"
  />
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SelectBox from "@/components/commonV2/ui/SelectBox.vue";
const formatService = require("@/utils/format.js");

export default {
  components: { SelectBox },
  props: {
    isAllShow: {
      type: Boolean,
      default: true
    },
    selectedValue: {
      type: [String],
    },
    selectStyleWidth: {
      type: String,
      default: "width: 200px",
    },
  },
  data() {
    return {
      productData: [],
      itemsHospitalType: formatService.hospitalTypeItem(),
    };
  },
  watch: {
    isAllShow: {
      handler(val, oldVal) {
        console.log('isAllShow', val);
        if(!val) {
          this.itemsHospitalType.splice(0, 1);
        }
      },
      immediate: true // 컴포넌트가 생성되자마자 즉시 실행
    }
  },
  methods: {
    changeSelect(e) {
      this.$emit("changeSelect", e);
    },
    ...mapActions(["getDataQuery"]),
  },
};
</script>
<style lang="">
</style>