<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :style="selectStyleWidth"
          :label="label"
          :disabled="propsDisabled"
          v-model="formedDate"
          prepend-inner-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <!-- :max="$moment().subtract(30, 'year').format('YYYY-MM-DD')" -->
      <!-- :max="$moment().subtract(30, 'day').format('YYYY-MM-DD')" 오늘부터 30일까지 선택불가 -->
      <v-date-picker
        v-model="date"
        :active-picker.sync="activePicker"
        :min="$moment().subtract(30, 'year').format('YYYY-MM-DD')"
        :max="$moment().add(1, 'year').format('YYYY-MM-DD')"
        :show-current="$moment().format('YYYY-MM-DD')"
        @change="save"
      />
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    selectStyleWidth: {
      type: String,
      default: "width: 150px",
    },
    propsDisabled: {
      type: Boolean,
      default: false,
    },
    propsGap: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    activePicker: null,
    date: null,
    formedDate: null,
    menu: false,
  }),
  watch: {
    value(val) {
      this.formedDate = this.parseDate(val);
      setTimeout(() => (this.activePicker = "YEAR"), 300);
    },
  },
  mounted() {
    this.formedDate = this.parseDate(this.value);
  },
  methods: {
    _resSetDate() {
      this.formedDate = "";
    },
    save(date) {
      this.$refs.menu.save(this.parseDate(date));
      this.formedDate = this.parseDate(date);
      this.$emit("onChangeDate", date);
    },
    parseDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}-${day.padStart(2, "0")}-${year}`; //.substr(2, 2)
    },
  },
};
</script>

<style>
.vGap {
  height: 5px;
}
</style>
