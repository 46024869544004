<template>
  <div class="subMain">
    <div class="headTitle">{{ $t("clinic.basicTitle") }}</div>
    <div class="_vGap"></div>
    <div class="edit_vGap" v-if="clinicData.user_id === 0"></div>
    <v-card-text>

      <!-- 병원종류 -->
      <SelectBoxHospitalType 
        selectStyleWidth="width: 100%"
        :isAllShow="false"
        :selectedValue="clinicData.hospitalType"
        @changeSelect="hospitalType_changeSelect"/>

      <TitleField 
        ref="refTitleField"
        :label="$t('clinic.detail.clinicName')" 
        :placeholder="$t('clinic.detail.clinicName')"
        :titleData="clinicData.hospitalName" 
        :rules="[filedRules.required, filedRules.counter]" 
        @complete="clinicData.hospitalName = $event"/>

      <!-- 병원정보 -->
      <TextArea 
        label="병원소개" 
        placeholder="병원소개"
        :isOutlined="false"
        :titleData="clinicData.hospitalText" 
        @complete="clinicData.hospitalText = $event"/>

      <div class="vGap" />

      <!-- 주소 -->
      <Address :data="clinicData && clinicData.address" />

      <v-row>
        <v-col>
          <v-text-field
            :label="$t('clinic.detail.email')"
            :placeholder="$t('clinic.detail.email')"
            ref="refEmail"
            v-model="clinicData.email"
            :rules="[emailRules.required, emailRules.counter, emailRules.email]"
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            :label="$t('clinic.detail.mobile')"
            :placeholder="$t('clinic.detail.mobile')"
            ref="refCell"
            v-model="clinicData.cell"
            :rules="[cellRules.required, cellRules.min, cellRules.max]"
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            :label="$t('clinic.detail.eligible')"
            :placeholder="$t('clinic.detail.eligible')"
            ref="refEligible"
            v-model="clinicData.eligible"
            :rules="[eligibleRules.required]"
            required
          />
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>
<script>
import SelectBoxHospitalType from "@/components/commonV2/ui/SelectBoxHospitalType.vue";
import TitleField from "@/components/commonV2/ui/lang/TitleField.vue";
import TextArea from "@/components/commonV2/ui/lang/TextArea.vue";
import Address from "@/components/commonV2/ui/AddressListItemKr.vue";
const formatService = require("@/utils/format.js");
const rulesService = require("@/utils/rules.js");

export default {
  components: {
    SelectBoxHospitalType,
    Address,
    TitleField,
    TextArea
  },
  props: {
    clinicData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      filedRules: rulesService.filedRules(),
      emailRules: rulesService.emailRules(),
      eligibleRules: rulesService.eligibleRules(),
      cellRules: rulesService.cellRules(),
    };
  },
  methods: {
    hospitalType_changeSelect(e) {
      this.clinicData.hospitalType = e;
      this.$emit("hospitalTypeChange", e);
    },
  },
};
</script>
<style lang="scss">
</style>