<template>
  <v-card-actions class="d-flex flex-reverse align-center mb-3 justify-end">
    <v-btn v-if="propsKey > 0 && isShowChangePassWordBtn" color="danger" outlined large @click="clickChangePassWord">
      Change Password </v-btn>
    <v-btn v-if="isShowSaveBtn" color="primary" depressed large @click="save">
      <!-- {{ propsKey > 0 ? saveBtnLabel : saveBtnLabel !== 'UPDATE' ? saveBtnLabel : $t('common.button.save') }} -->
      {{ this.makeSaveBtnLabel() }}
    </v-btn>
    <v-btn v-if="propsKey > 0 && isShowDeleteBtn" color="danger" outlined large @click="clickDelete">
      {{ $t('common.button.delete') }}
    </v-btn>
    <v-btn color="primary" outlined large @click="cancel"> {{ cancelBtnLabel }} </v-btn>
  </v-card-actions>
</template>
<script>
export default {
  props: {
    propsKey: {
      type: [Number],
    },
    isShowChangePassWordBtn: {
      type: Boolean,
      default: false
    },
    isShowSaveBtn: {
      type: Boolean,
      default: true
    },
    isShowDeleteBtn: {
      type: Boolean,
      default: true
    },
    saveBtnLabel: {
      type: String,
      default: 'UPDATE'
    },
    cancelBtnLabel: {
      type: String,
      default: 'CANCEL'
    }
  },
  methods: {
    save() {
      this.$emit("save")
    },
    clickDelete() {
      this.$emit("delete")
    },
    cancel() {
      this.$emit("cancel")
    },
    clickChangePassWord() {
      this.$emit("changePassWord")
    },
    // 버튼을 OK로 하기위해서
    makeSaveBtnLabel() {
      if (this.propsKey > 0) {
        return this.saveBtnLabel
      }
      else if (this.saveBtnLabel !== 'UPDATE') {
        return this.saveBtnLabel;
      }
      else {
        return this.$t('common.button.save')
      }
    }
  },
};
</script>
<style lang="">
</style>