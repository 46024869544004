<template>
  <v-dialog v-model="visible" max-width="1300px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title> Image Viewer </v-card-title>
      <v-card-text>
        <img class="imgSize" :src="fileLink" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Header from "./comp/Header.vue";
export default {
  components: { Header },
  props: {
    // fileLink: {
    //   type: String,
    //   default: '',
    // },
  },
  data() {
    return {
      visible: false,
      fileLink: "",
    };
  },
  methods: {
    Open(url) {
      this.fileLink = url;
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  },
};
</script>
<style lang="scss">
.imgSize {
  width: 100%;
}
</style>