<template>
  <div class="subMain">
    <div class="headTitle">의료기관 증명서관련 정보</div>
    <div class="_vGap"></div>
    <div class="edit_vGap" v-if="clinicData.user_id === 0"></div>
    <v-card-text>
      <v-row>
        <v-col>
          <CheckComps
            ref="refChangeCertifyFiles"
            :isHorizontal="false"
            :items="certifyFilesItems"
            :value="certifyFilesValue"
            @changeType="changeCertifyFiles"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ClinicCertifyFilesUpload
            ref="refClinicCertifyFilesUpload"
            :ownerData="clinicData"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>
<script>
import CheckComps from "@/components/commonV2/ui/CheckComps.vue";
import ClinicCertifyFilesUpload from "./ClinicCertifyFilesUpload.vue";
const formatService = require("@/utils/format.js");
const rulesService = require("@/utils/rules.js");

export default {
  components: {
    CheckComps,
    ClinicCertifyFilesUpload,
  },
  props: {
    clinicData: {
      type: [Object, Array],
    },
  },
  watch: {
    clinicData(val) {
      if (val === null) return;
      this.certifyFilesValue =
        val.certifyFiles === ""
          ? []
          : this.$helper.changeStringToArr(val.certifyFiles);
    },
  },
  data() {
    return {
      certifyFilesValue: [],
      certifyFilesItems: formatService.certifyFilesItems(),
    };
  },
  methods: {
    changeCertifyFiles(e) {
      // console.log("", e);
      this.clinicData.certifyFiles = e.toString();
    },
  },
};
</script>
<style lang="scss">
</style>