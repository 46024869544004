<template>
  <div>
    <v-textarea
      counter="1000"
      maxLength="1000"
      v-model="titleObj.kr"
      :outlined="isOutlined"
      :placeholder="placeholder"
      :label="label"
      :rules="rules"
      @input="changeInput"
    />
    <v-btn class="btn" color="primary mt-5" depressed small @click="clickI18">
      {{ label }} 다국어처리
    </v-btn>

    <DialogTextAreaI18
      :label="label"
      ref="refDialogTextAreaI18"
      @complete="complete"
    />
  </div>
</template>
<script>
import DialogTextAreaI18 from "./DialogTextAreaI18.vue";
export default {
  components: { DialogTextAreaI18 },
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    titleData: {
      type: String,
    },
    isOutlined: {
      type: Boolean,
      default: true
    },
    rules: {
      type: [Object, Array],
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      titleObj: {
        kr: "",
        en: "",
        ch: "",
      },
    };
  },
  watch: {
    titleData: {
      handler(val, oldVal) {
        let tD = this.$helper.makeStringToArray(val, "@@");
        this.titleObj.kr = tD.length > -1 ? tD[0] : "";
        this.titleObj.en = tD.length > 0 ? tD[1] : "";
        this.titleObj.ch = tD.length > 1 ? tD[2] : "";
        this.titleObj.bt = tD.length > 2 ? tD[3] : "";
      },
      immediate: true, // 컴포넌트가 생성되자마자 즉시 실행
    },
    // titleData(val) {
    //   let tD = this.$helper.makeStringToArray(val, "@@");
    //   this.titleObj.kr = tD.length > -1 ? tD[0] : "";
    //   this.titleObj.en = tD.length > 0 ? tD[1] : "";
    //   this.titleObj.ch = tD.length > 1 ? tD[2] : "";
    // }
  },
  methods: {
    clickI18() {
      this.$refs.refDialogTextAreaI18.Open(this.titleObj);
    },
    changeInput(e) {
      // console.log("e", e);
      // console.log("titleObj", this.titleObj);
      this.complete(this.titleObj);
    },
    complete(e) {
      if (e === null) return;
      let str = e.kr + "@@" + e.en + "@@" + e.ch + "@@" + e.bt;
      this.$emit("complete", str);
    },
  },
};
</script>
<style lang="scss">
.btn {
  width: 98%;
}
</style>