<template>
  <v-row>
    <v-col cols="3">
      <span>{{ prosTitle }}</span>
    </v-col>
    <v-col class="d-flex" cols="2">
      <v-text-field
        ref="refBasicSurgery"
        class="centered-input"
        label="수술"
        placeholder="수술"
        type="Number"
        v-model="surgery"
        @input="finallyEmit"
      />
      <div class="prot">%</div>
    </v-col>
    <v-col class="d-flex" cols="2">
      <v-text-field
        ref="refBasicSurgery"
        class="centered-input"
        label="시술"
        placeholder="시술"
        type="Number"
        v-model="procedure"
        @input="finallyEmit"
      />
      <div class="prot">%</div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  components: {},
  props: {
    clinicData: {
      type: [Object, Array],
    },
    prosTitle: {
      type: [String],
    },
    prosSurgery: {
      type: [Number, String],
    },
    prosProcedure: {
      type: [Number, String],
    },
  },
  watch: {
    prosSurgery: {
      handler(val, oldVal) {
        this.surgery = val;
      },
      immediate: true
    },
    prosProcedure: {
      handler(val, oldVal) {
        this.procedure = val;
      },
      immediate: true // 컴포넌트가 생성되자마자 즉시 실행
    }
  },
  data() {
    return {
      surgery: this.prosSurgery,
      procedure: this.prosProcedure
    }
  },
  methods: {
    finallyEmit() {
      let d = {
        surgery: this.surgery,
        procedure: this.procedure
      }
      this.$emit("changeComplete", d);
    }
  }
};
</script>
<style lang="scss">
.prot {
  padding-top: 20px;
}
.centered-input input {
  text-align: center;
}
</style>