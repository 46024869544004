<template>
  <div class="box boxOnly">
    <v-card class="editorPadding">
      <div>
        <v-row>
          <v-col cols="10">
            <S3_FileUpload_V4
              :visible="false"
              ref="refS3FileUploader"
              uploadType="file"
              uploadDir="file"
              fileHeader="certifyFile"
              accept=".pdf, .xlsx, .hwp, .doc, .docx, .ppt, .pptx, .jpg, .jpeg, .png, .gif"
              :fileInfo="fileInfo"
              @fileSelected="fileSelected"
              @fileSelectedCheck="fileSelectedCheckS3FileUploader"
              @uploadComplete="uploadComplete"
              @changeDeleteOpt="changeDeleteOpt"
            />
          </v-col>
        </v-row>

        <DataTable
          ref="DataTable"
          :loading="loading"
          :loadingText="loadingText"
          :tableKey="'rowSeq'"
          :footerHide="true"
          :singleSelect="true"
          :showSelect="false"
          :columns="tableColumns"
          :inputData="uploadedFiles"
          @imageClick="imageClick"
          @RemoveClick="RemoveClick"
        />
      </div>
    </v-card>

    <DialogImageViewer ref="refDialogImageViewer" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import DatePickerWithInputText from "@/components/commonV2/ui/DatePickerWithInputText.vue";
import S3_FileUpload_V4 from "@/components/commonV2/ui/S3_FileUpload_V4.vue";
import DataTable from "@/components/commonV2/table/DataTable.vue";
import DialogImageViewer from "@/components/commonV2/Dialog/DialogImageViewer.vue";
const formatService = require("@/utils/format.js");
const modelService = require("@/utils/dataModel");

export default {
  components: {
    DatePickerWithInputText,
    S3_FileUpload_V4,
    DataTable,
    DialogImageViewer,
  },
  props: {
    ownerData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      loading: false,
      loadingText: "Loading list...",
      fileInfo: null,
      fileDeleteOpt: false,
      uploadDefaultDate: this.$helper.getToDateStringType(),
      uploadFilesModel: modelService.uploadFilesModel(),
      uploadedFiles: [],
      tableColumns: formatService.uploadFilesTableColumns(),
    };
  },
  watch: {
    ownerData(val) {
      if (val === null) return;
      this.uploadFilesModel.fileGbn = "certify";
      this.uploadFilesModel.user_id = val.user_id === undefined ? 0: val.user_id;
      this.uploadFilesModel.hospital_id = val.hospital_id;
      this.getUploadFilesData();
    }
  },
  // mounted() {
  //   this.uploadFilesModel.fileGbn = "certify";
  //   this.uploadFilesModel.user_id = this.ownerData.user_id === undefined ? 0: this.ownerData.user_id;
  //   this.uploadFilesModel.hospital_id = this.ownerData.hospital_id;
  //   this.getUploadFilesData();
  // },
  methods: {
    async getUploadFilesData() {
      this.getDataQuery(this.uploadFilesModel).then((resP) => {
        if (resP === undefined) return;
        // console.log('res', res);
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: res.length - index,
            regDateLabel: this.$helper.changeDateToStringForDp(item.regDate,true),
            consentDateLabel: this.$helper.changeDateToStringForDp(item.consentDate),
            userType: 'uploadFiles' // rowClick추가
          }));
        }
        this.uploadedFiles = res;
      });
    },
    fileSelected(e) {
      this.uploadFilesModel.fileOriginalName = e.name;
      this.$refs.refS3FileUploader.uploadFileToS3();
    },
    changeDeleteOpt(e) {
      this.fileDeleteRequestImg = e;
    },
    /** S3업로드 처리 후 */
    uploadComplete(e) {
      console.log("uploadComplete", e);
      if (e === null) return;
      this.uploadFilesModel.fileUrl = e.Location;
      this.saveAction();
    },
    /*** 업로드 후 */
    RemoveClickConsent(item) {
      if (!confirm("Are you sure you want to Remove?")) return;
      this.uploadFilesModel.f_id = item.f_id;
      this.uploadFilesModel.useYN = "N";
      this.saveAction();
    },
    async saveAction() { 
      this.saveDataQuery(this.uploadFilesModel).then((res) => {
        if (res === undefined) return;
        this.getUploadFilesData();
        // 초기화
        this.fileInfo = null;
        this.$refs.refS3FileUploader._resetForm();
      });
    },
    RemoveClick(item) {
      console.log('RemoveClick');
      if (!confirm('Are you sure you want to Remove?')) return;
      this.uploadFilesModel.f_id = item.f_id;
      this.uploadFilesModel.useYN = 'N';
      this.saveAction();
    },
    imageClick(item) {
      if (!this.$helper.checkImageFile(item.fileUrl)) {
        window.open(item.fileUrl, "_blank")
        return;
      }
      /** 이미지 파일이면 보이게 처리 */
      this.$refs.refDialogImageViewer.Open(item.fileUrl);
    },
    fileSelectedCheckS3FileUploader(e) {},
    ...mapActions(["getDataQuery", "saveDataQuery"]),
  },
};
</script>
<style lang="scss">
.boxOnly {
  width: 98% !important;
  .inBox {
    width: 100% !important;
  }
}
</style>