import { render, staticRenderFns } from "./DialogTextAreaI18.vue?vue&type=template&id=1fd31f50&"
import script from "./DialogTextAreaI18.vue?vue&type=script&lang=js&"
export * from "./DialogTextAreaI18.vue?vue&type=script&lang=js&"
import style0 from "./DialogTextAreaI18.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCard,VDialog,VTextarea})
